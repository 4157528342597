<template>
  <div class="sample" v-if="$store.state.sampleserver==1">
      <span class="txt">샘플서버 입니다. 입금 NO!!! 출금 NO!!!</span>
  </div>
</template>

<script>
export default {
  name: "WarningForSample"
}
</script>

<style scoped>
.sample {
  position: absolute;
  top: 2rem;
  z-index: 99;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.8);

}
.sample .txt{
  animation: heartBeat;
  animation-duration: 1.7s;
  animation-iteration-count: infinite ;
}

</style>