<template>
  <div class="content">
    <sub-title>충전</sub-title>
    <div class="reg_box">
      <warning-for-sample></warning-for-sample>
      <div class="items l">
        <div class="itm title">
          <div class="t1">환전요청</div>
          <div class="t2"></div>
        </div>

        <div class="itm">
          <div class="rolling_info">
            <div class="rolling_detail">
              <div class="rolling_title">
                스포츠 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.sports }} %
              </div>
            </div>
            <div class="rolling_detail">
              <div class="rolling_title">
                미니게임 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.minigame }} %
              </div>
            </div>
            <div class="rolling_detail">
              <div class="rolling_title">
                카지노 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.honorcasinototalpercent }} %
              </div>
            </div>
            <div class="rolling_detail">
              <div class="rolling_title">
                슬롯 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.honorslottotalpercent }} %
              </div>
            </div>
          </div>


        </div>

        <div class="itm">
          <div class="t1">보유머니</div>
          <div class="t2">
                          <span class="tit">
                            {{ $store.state.userInfo.cash|comma }}
                          </span>

          </div>
        </div>

        <div class="itm">
          <div class="t1">환전금액</div>
          <div class="t2">
                          <span class="tit" style="font-size: 11px">
                            환전하실 금액을 기입하시고 [충전요청]버튼을 클릭하세요.
                            환전은 만원단위로 가능합니다

                          </span>
            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
              <input type="text" v-model="applyAmount" class="re_input" placeholder="환전금액"
                     @input="setComma">
              <button type="button" class="reset" @click="changeMoney(0)">정정하기
              </button>
            </div>
            <div class="addcashbuttons"
                 style="display: flex; justify-content: flex-start;width: 100%;padding: 3px 0;flex-wrap: wrap">
              <button type="button" @click="changeMoney(10000)">
                1만
              </button>
              <button type="button" @click="changeMoney(50000)">
                5만
              </button>
              <button type="button" @click="changeMoney(100000)">
                10만
              </button>
              <button type="button" @click="changeMoney(500000)">
                50만
              </button>
              <button type="button" @click="changeMoney(1000000)">
                100만
              </button>
              <button type="button" @click="changeMoney(5000000)">
                500만
              </button>
            </div>

          </div>
        </div>

        <div class="itm">
          <div class="t1">회원정보</div>
          <div class="t2">

            <span style="margin-top: 5px;">이름: {{ $store.state.userInfo.beneficiary }}</span>
            <span style="margin-top: 5px;">계좌번호:{{ $store.state.userInfo.acNo }}</span>
            <span style="margin-top: 5px;">은행명:{{ $store.state.userInfo.bank }}</span>
            <span style="margin-top: 5px;">전화번호:{{ $store.state.userInfo.phone }}</span>
            <span class="tit" style="font-size: 11px;margin-top: 10px;color: #ff0000">
                           연락처가 틀리거나 바뀌신 회원님은 고객센터로 남겨주세요
                          </span>
          </div>
        </div>

        <div class="itm">
          <div class="t1"></div>
          <div class="t2">
            <button class="askacno" @click="exchange(0)">환전요청</button>
          </div>
        </div>

      </div>

      <div class="items r">
        <div class="itm title">
          <div class="t1">환전안내</div>
          <div class="t2"></div>
        </div>
        <div class="itm infotextexchange">
          <div v-html="infotext"></div>
        </div>
        <div class="re_history">
          <table class="utable" cellpadding="0" cellspacing="0" border="0">
            <colgroup>
              <col width="27%">
              <col width="27%">
              <col width="27%">
              <col width="15%">
            </colgroup>
            <thead>
            <tr>
              <th>일자</th>
              <th>금액</th>
              <th @click="refreshData()" style="cursor: pointer">상태 <i class="fa fa-refresh"
                                                                       aria-hidden="true"></i>
              </th>
              <th>-</th>
            </tr>
            <tr v-for="(item,indx) in exchangeList" :key="indx">
              <td>{{ item.createTime|datef('MM/DD HH:mm') }}</td>
              <td>{{ item.amount|comma }}</td>
              <td>
                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">확인중</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE" style="color: #35c82d">완료</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                      style="color: red">실패</span>
              </td>
              <td>
                  <span
                      style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer;color: red"
                      v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> 삭제
                  </span>

              </td>
            </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import {
  deleteApply,
  exchangeCash,
  getApplyCashList,
  getinfotext,
  getMyRolling,
} from "@/network/userRequest";
import {
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
} from "@/store/mutation-types";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import {postionMixin} from "@/common/mixin";
import WarningForSample from "@/views/afront/zero/WarningForSample.vue";

export default {
  name: "Exchange",
  mixins: [postionMixin],
  components: {
    WarningForSample,
    sportsConst,SubTitle
  },
  data() {
    return {
      sportsConst,
      infotext:null,
      applyCash: {
        amount: 0,
        extractPasswd: ''
      },
      applyAmount: '',
      search: {applyType: sportsConst.APPLY_TYPE_EXCHANGE},
      exchangeList: [],
      casinocash: 0,
      position: "출금",
      rolling: {
        "reachcasino": 0,
        "minigame": 0,
        "honorcasino": 0,
        "total": 0,
        "sports": 0,
        "tgame": 0,
        "tcasino": 0,
        "casinoapply": 0,
        "tslot": 0
      }
    }
  },
  methods: {
    setComma() {
      if (this.applyAmount == '') {
        this.applyAmount = ''
      } else {
        this.applyAmount = this.$root.$options.filters.comma(this.applyAmount)
      }
    },
    changeMoney(cash) {
      if (cash === 0) {
        this.applyAmount = '';
      } else {
        let c = this.applyAmount == ''  ? 0 : this.applyAmount.replaceAll(',', '')
        this.applyAmount = this.$root.$options.filters.comma(parseInt(c) + parseInt(cash));

      }
    },
    exchange() {
      this.applyCash.amount = this.applyAmount.replaceAll(',', '');
      this.$swal({
        title: this.applyCash.amount + '원 환전신청 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          exchangeCash(this.applyCash).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.$swal({
                title: '환전신청성공',
                type: 'success',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.applyAmount = ''
              this.applyCash.amount = 0
              this.$store.dispatch('actionUserInfo')
              this.initExchangeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    change2sportsmoney() {
      this.$swal({
        title: '스포츠머니로 전환 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          subjectMemberCasinoCash().then((res) => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            this.$swal({
              title: '전환성공',
              confirmButtonText: '확인',
              type: 'success',
              showCancelButton: false,
              showConfirmButton: true
            })
            this.casinocash = 0;
            this.$store.dispatch('actionUserInfo')
            setTimeout(() => {
              this.getCasinoBalance();
            }, 1300)
          })
        }
      })

    },
    initExchangeList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getApplyCashList(this.search).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.exchangeList = res.data.data
        }
      })
    },
    refreshData() {
      this.initExchangeList()
      this.$bus.$emit('applyRequestSuccess')
    },
    deleteById(id) {
      this.$swal({
        title: '삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteApply(id).then(res => {
            if (res.data.success) {
              this.initExchangeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    getCasinoBalance() {
      getAccountBalance().then(res => {
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    },
    getInfoText(){
      getinfotext().then(res=>{
        this.infotext = res.data.data.exchangeText
      })
    }
  },
  created() {
    this.getInfoText()
    this.initExchangeList()
    getMyRolling().then(res => {
      if (res.data.success) {
        this.rolling=res.data.data;
      }
    })
  },
  mounted() {
  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontapply.css");
</style>